<template>
  <div class="overlay-loading" v-show="isWaitingForServer"></div>
  <div class="Probability-actions">
    <h2>Select Probability game</h2>
    
    <ul class="list-buttons">
      <li v-for="game in games" v-bind:key="game.id">
        <button class="button" @click.stop="chooseGame(game)" :class="(chosenGame!=null && game.id==chosenGame.id?'disabled':'enabled')">{{game.name}} >></button>
      </li>
    </ul>
    <span v-if="chosenGame" class="title">Select your wagers</span>
    <ul v-if="chosenGame" class="vertical-inputs">
      <li v-for="wl in chosenGame.probabilities" v-bind:key="wl.id">
        <p><label for="wager">Wager {{wl.name}} wager ({{wl.minPrice}}-{{wl.maxPrice}}): </label>
          <input type="number" v-model="wl.wager" :min="chosenGame.minPrice" :max="chosenGame.maxPrice"> SEK
        </p>
      </li>
    </ul>
    <button v-if="chosenGame" class="button" @click.stop="buy()">Buy ticket</button>
    
    <div class="unfinished" v-if="unfinishedTickets.length > 0">
      <h2>Unfinished tickets</h2>
      <ul class="unfinished-list">
        <li v-for="ticket in unfinishedTickets" v-bind:key="ticket.correlationId">
          <strong>Correlation ID: {{ ticket.correlationId }} </strong> - <a href="#" @click.stop="finalizeGameCollection(ticket)">Finalize GameCollection</a>
          <br/><span v-if="ticket.tickets[0].autoCompleteOn" class="autocomplete">( AutoCompletes on: {{ $filters.formatDate(ticket.tickets[0].autoCompleteOn) }} )</span>
          <br/> - Wager: {{ ticket.gamePrice }} SEK <br/>
          <ul>
            <li v-for="partialTicket in ticket.tickets" v-bind:key="partialTicket.id">
              Ticket for: <strong><i>{{ partialTicket.gameName }}</i></strong> ID: {{ partialTicket.id }} <br/> - Wager: {{ partialTicket.price }} SEK  - <a href="#" @click.stop="getTicket(partialTicket)">Play</a> - <a v-if="!partialTicket.finalized" href="#" @click.stop="finalizeSingleTicket(partialTicket)">Finalize Ticket</a><span v-else>Finalized</span>
              <div v-if="viewedTicket && viewedTicket.correlationId == ticket.correlationId">
                  <li>
                    Earned prizes: {{ viewedTicket.earnedPrizes }}
                  </li>
                  <li>
                    Total prizeValue: {{ viewedTicket.prizeValue }}
                  </li>
                  <div v-if="viewedTicket.currentLevel">
                    <strong>{{ `Current Level is: #${viewedTicket.currentLevel.orderNumber} - ${viewedTicket.currentLevel.name}` }}</strong><br/>
                    <button 
                      @click.stop="pickNumber(partialTicket, number.number)" 
                      class="button"
                      :disabled="number.pickInfo" 
                      v-for="number in viewedTicket.currentLevel.numbersInLevel" v-bind:key="number">
                      {{ number.pickInfo ? `#${number.number} - PrizeLevel: ${number.pickInfo.levelPrizePlanId} Value: ${number.pickInfo.prizeValue}` : `Pick #${number.number}`}}
                    </button>
                    <br/>
                    <button
                      @click.stop="makeCashOut(partialTicket)" 
                      class="button cashOut"
                      :disabled="!viewedTicket.currentLevel.cashOutValue">
                      {{ viewedTicket.currentLevel.cashOutValue ? `Cash Out ${viewedTicket.currentLevel.cashOutValue} SEK` : 'Cash Out'}} <mdicon name="cash-fast" :size="24" class="mb-2"/>
                    </button>
                </div>
                <div v-else>
                  <strong>Ticket completed!</strong>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="ticket-result" v-if="lastTicketResultWinAmount !== null">
      <h2>Ticket result</h2>
      <strong>Result:</strong> {{ lastTicketResultWinAmount }} SEK
    </div>
  </div>
</template>

<script>
import store from '../store.js';
import { updateBalance, buyGameCollection, getUnfinishedTickets, finalizeGameWithCorrelationId, getProbabilityTicket, finalizeGameTicket, pickProbabilityNumber, cashOut } from '../api.js';

const { VUE_APP_GAMES_PROBABILITY } = process.env;


export default {
  name: 'ProbabilityGame',
  props: {
  },
  mounted() {
    this.games = JSON.parse(VUE_APP_GAMES_PROBABILITY);
  },
  data() {
    return {
      isWaitingForServer: false,
      chosenGame: null,
      store: store,
      currentCorrelationId: '',
      currentStatus: '',
      unfinishedTickets: [],
      lastTicketResultWinAmount: null,
      games: [],
      wagers: [],
      viewedTicket: null
    }
  },
  computed: {
    formattedJson() {
      return JSON.stringify(this.viewedTicket, null, 2);
    },
    
  },
  methods: {
    chooseGame(_game) {
      this.chosenGame = _game;
      this.checkUnfinishedTickets();
      this.lastTicketResult = null;
    },
    checkUnfinishedTickets() {
      this.isWaitingForServer = true;
      getUnfinishedTickets(this.chosenGame.id).then((data) => {
        this.unfinishedTickets = data;
      }).catch(() => {
        this.unfinishedTickets = null;
      });
      this.isWaitingForServer = false;
    },
    buy() {
      this.lastTicketResultWinAmount = null;
      if (!this.chosenGame) return false;
      
      let buyArguments = this.chosenGame.probabilities.map(function (element) { 
        return {
          gameType: "Probability",
          arguments: JSON.stringify({
            ProbabilityId: element.id,
            buyPrice: element.wager
          })
          }
        } 
      )

      this.isWaitingForServer = true;
      let response = buyGameCollection(this.chosenGame.id, buyArguments).then((data) => {
        console.log('buy', this.chosenGame, data);
        console.dir(response);
        this.checkUnfinishedTickets();
        updateBalance();
        this.isWaitingForServer = false;
      }).catch(() => {
        this.isWaitingForServer = false;
      });
    },
    async makeCashOut(_ticket){
      await cashOut(_ticket.id);
    },
    async pickNumber(_ticket, number){
      this.viewedTicket = await pickProbabilityNumber(_ticket.id, number);
    },
    async getTicket(_ticket){
      this.isWaitingForServer = true;
      getProbabilityTicket(_ticket.id).then(
      (data) => {
        this.isWaitingForServer = false;
        this.viewedTicket = data;
      });
      this.isWaitingForServer = false;
    },
    finalizeGameCollection(_ticket) {
      this.isWaitingForServer = true;

      finalizeGameWithCorrelationId(_ticket.correlationId).then((data)  => {
        let totalWin = 0;

        data.tickets.forEach(ticket => {

          if (ticket.isWinner) { 
            getProbabilityTicket(ticket.id).then((data) => {
                return totalWin += data.winAmount;
              }).then((value) => {
                this.lastTicketResultWinAmount = value;
              });
            } else {
              this.lastTicketResultWinAmount = totalWin;
            }
        });
        
        this.isWaitingForServer = false;
        this.checkUnfinishedTickets();
        updateBalance();
      });
    },
    async finalizeSingleTicket(_ticket) {
      this.isWaitingForServer = true;

      var result = await finalizeGameTicket("PUT", "Probability", _ticket.id);
      this.lastTicketResultWinAmount = result.prize;
      this.checkUnfinishedTickets();
      updateBalance();
      this.isWaitingForServer = false;
    }
  }
}
</script>

<style scoped>

.winClass {
  color: green;
}
.unfinished-list > li {
  margin: 14px;
}
.cashOut, .cashOut:disabled {
  background: green;
}
.cashOut:disabled {
  opacity: 0.5;
}
ul.list-buttons {
  list-style-type: none;
  padding: 0;
}
ul.vertical-inputs {
  list-style-type: none;
  padding: 0;
}
ul.list-buttons li {
  display: inline-block;
  margin: 0 50px 0 0;
}
ul.vertical-inputs li {
  display: block;
  margin: 0 0 0 0;
  width: 50%;
}

ul.vertical-inputs li p input {
  width: 10%;
}

.title {
  font-weight: bold;
}

li p {
  margin: 0
}
.autocomplete {
  font-size: 11px;
}

</style>
