<template>
  <nav class="menu">
    <ul>
      <li v-if="!isLoggedIn" :class="store.page=='login'?'active':''"><a href="#" @click.stop="navigate('login')">Log in</a></li>
      <li v-if="isLoggedIn" :class="store.page=='raffle'?'active':''"><a href="#" @click.stop="navigate('raffle')">Raffle</a></li>
      <li v-if="isLoggedIn" :class="store.page=='gamification'?'active':''"><a href="#" @click.stop="navigate('gamification')">Gamification</a></li>
      <li v-if="isLoggedIn" :class="store.page=='jackpot'?'active':''"><a href="#" @click.stop="navigate('jackpot')">Jackpot</a></li>
      <li v-if="isLoggedIn" :class="store.page=='winlose'?'active':''"><a href="#" @click.stop="navigate('winlose')">Win/Lose</a></li>
      <li v-if="isLoggedIn" :class="store.page=='probability'?'active':''"><a href="#" @click.stop="navigate('probability')">Probability</a></li>
      <li v-if="isLoggedIn" :class="store.page=='transactions'?'active':''"><a href="#" @click.stop="navigate('transactions')">Transactions</a></li>
    </ul>
  </nav>
</template>

<script>
import store from '../store.js';

export default {
  name: 'MainMenu',
  props: {
  },
  computed: {
    isLoggedIn() {
      return !!store.user?.access_token;
    }
  },
  data() {
    return {
      store: store,
    }
  },
  methods: {
    navigate(_newpage) {
      store.page = _newpage;
      return false;
    }
  }
}
</script>

<style scoped>
nav.menu {
  width: 100%;
  display: flex;
  background: var(--color-blue);
  color: var(--color-text-light);
}
nav.menu ul {
  display: flex;
  padding-left: 10px;
}
nav.menu ul a {
  text-decoration: none;
  color: var(--color-text-light);
}
nav.menu ul li.active a {
  text-decoration: underline;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
